@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.h-full {
  height: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.sidebar {
  border-right: 1px solid #ccc;
  flex: 0 0 40%;
}

.chat {
  flex: 0 0 60%;
}

@media (max-width: 1024px) {
  .sidebar {
    width: 100%;
  }
  .chat {
    display: none;
  }
}
