:root {
  --primary-color: #008080;
  --secondary-color: #228B22;
  --tertiary-color: #87CEEB;
  --quaternary-color: #708090;
  --text-color: #2F4F4F;
  --background-color: #cfe8f4;
  --button-text-color: #FFFFFF;
  --experimental-color: #c8e9ff88;
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
}

button.primary {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}

button.secondary {
  background-color: var(--secondary-color);
  color: var(--button-text-color);
}



.card {
  background-color: var(--tertiary-color);
}

.icon {
  color: var(--quaternary-color);
}
.exp{
  color: #2c3336;
}



.h-full {
  height: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}
.sidebar {
  border-right: 1px solid #ccc;
}

@media (min-width: 1024px) {
  .sidebar {
    flex: 2;
  }
  .chat {
    flex: 3;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    flex: 1;
  }
  .chat {
    display: none;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
